<template>
  <loading v-if="isHttpData" style="margin-top: 2.1rem"></loading>
  <div v-else>
    <div class="hotel-box" v-if="isNull" id="scroll" :style="{'--themecolor': themecolors,'--rgbathemecolor': 'rgba(0, 204, 176,0.3)',}">
      <div class="main">
        <div v-if="hotel_info.room_id">
          <div class="banner">
            <van-swipe
              class="my-swipe"
              :autoplay="3000"
              indicator-color="white"
            >
              <van-swipe-item v-for="(i, j) in hotel_info.carousel_id" :key="j">
                <img class="swipe-img" :src="i.image_url" alt="" />
                <!-- <video v-if="i.file_type == 2" :src="i.image_url" autoplay></video> -->
              </van-swipe-item>
            </van-swipe>
          </div>
          <div class="site-content flex-center between">
            <div style="width: 85%">
              <h2>{{ hotel_info.hotel_name }}</h2>
              <p>{{ hotel_info.details_address }}</p>
            </div>
            <div class="address flex between">
              <!-- <div>
                        <p class="flex-center"><img src="@/assets/icon/address.svg" alt="">据您直线163米</p>
                        <p class="vehicle"><img src="@/assets/icon/vehicle.svg" alt=""><span>据南翔汽车站直线85米，步行约138米，预计13分钟</span></p>
                    </div> -->
              <p @click="getMap">
                <img
                  src="@/assets/icon/map.svg"
                  style="width: 0.17rem; margin: 0"
                  alt=""
                /><br />
                <span>地图</span>
              </p>
            </div>
          </div>
          <div class="prompt flex-center">
            <p>
              订房须知：<span>{{ hotel_info.notice }}</span>
            </p>
          </div>
          <div
            class="date-tabs flex-center between"
            @click="calendar.show = true"
          >
            <p>{{ calendar.date[0] }}<span>入住</span></p>
            <div class="flex-center center">
              {{ calendar.number_nights }} 晚
            </div>
            <p>{{ calendar.date[1] }}<span>离店</span></p>
            <van-icon name="arrow" color="#CFD0D6" size="14" />
          </div>
          <div class="check-in flex-center">
            <span>*</span>
            <p>选择入住人数：</p>
            <p
              class="num flex-center center"
              v-for="(i, j) in hotel_info.living_population"
              :key="j + '-only'"
              :style="
                fill_info.num == i ? `background:rgba(0, 204, 176,0.3);` : ''
              "
              @click="changeNum(i)"
            >
              {{ i }}人
            </p>
          </div>
          <div class="hotel">
            <!-- 不可选颜色 #F7F8FC -->
            <van-radio-group
              v-model="fill_info.room_id"
              class="flex between"
              style="width: 100%"
            >
              <div
                class="hotel-item"
                v-for="(i, j) in hotel_info.room_id"
                :key="j + '-only'"
                :style=" fill_info.num == i.quantity ? fill_info.room_id == i.id ? `background:rgba(0, 204, 176,0.3);border-color:rgba(0, 204, 176,0.3)` : '' : 'background:#F7F8FC' "
                @click="fill_info.num == i.quantity ? checkRoom(i) : ''"
              >
                <div class="top flex between">
                  <p>
                    <img src="@/assets/icon/bed.svg" alt=""  v-for="i in 1" :key="i"/>
                  </p>
                  <van-radio
                    :name="i.id"
                    checked-color="#00ccb0"
                    :disabled="fill_info.num != i.quantity"
                  ></van-radio>
                </div>
                <p class="room_title">{{ i.room_title }}</p>
                <div class="bottom flex-center between">
                  <p class="room_price">
                    <span class="price">￥{{ i.price }}</span>
                  </p>
                  <p class="room_remaining">
                    剩{{ i.remaining }}间
                  </p>
                  <button
                    @click="fill_info.num == i.quantity ? checkReserve(i) : ''"
                    :style="fill_info.num == i.quantity ? '' : 'background:#999' ">
                    预定
                  </button>
                </div>
              </div>
            </van-radio-group>
          </div>
        </div>
        <div v-else class="hotel_null">
          <img src="@/assets/attend/none-img.svg" alt="" />
          暂无酒店配置信息
        </div>
      </div>
      <van-calendar
        v-model="calendar.show"
        type="range"
        :max-range="calendar.number_days"
        :color="themecolors"
        :min-date="calendar.minDate"
        :max-date="calendar.maxDate"
        :formatter="formatter"
        :default-date="this.calendar.defaultdate"
        @confirm="onConfirm"
        class="calendar"
        :style="{ height: '4.3rem' }"
      />
      <!-- 未缴费弹窗 -->
      <van-dialog
        class="vant-dialog"
        v-model="applydia"
        :show-confirm-button="false"
        :beforeClose="beforeClose"
      >
        <div class="header flex-center">
          <van-icon name="cross" @click="applydia = false" />
        </div>
        <p>{{ msg }}</p>
        <button @click="gohome">去报名</button>
      </van-dialog>
      <!-- 填写信息弹窗 -->
      <van-dialog
        class="vant-fill-dialog"
        v-model="fillIndia"
        :show-confirm-button="false"
        :beforeClose="beforeClose"
      >
        <div class="header flex-center between">
          <span></span>填写信息<van-icon
            name="cross"
            @click="fillIndia = false"
          />
        </div>
        <div class="user-info">
          <h2>{{ fill_info.room_title }}</h2>
          <p>
            {{ getLocalTime(Date.parse(this.calendar.date[0]), 3) }} -
            {{ getLocalTime(Date.parse(this.calendar.date[1]), 3) }} 共{{
              calendar.number_nights
            }}晚
          </p>
          <h2>入住信息</h2>
          <van-form @submit="add_hotel_user_info">
            <van-field
              v-model="fill_info.user_name"
              name="姓名"
              label="姓名"
              placeholder="请输入姓名"
              :rules="[{ required: true }]"
            />
            <!-- <van-field
              v-model="fill_info.idcard"
              name="身份证"
              label="身份证"
              placeholder="请输入身份证号"
              :rules="[{ required: true, validator: asyncValidator }]"
            /> -->
            <van-field
              v-model="fill_info.mobile"
              name="手机号"
              label="手机号"
              placeholder="请输入手机号"
              :rules="[{ required: true, validator }]"
            />
            <van-field
              v-model="fill_info.tow_user_name"
              v-if="fill_info.num > 1"
              name="同住人姓名"
              label="同住人姓名"
              placeholder="请输入同行人姓名，没有则填无"
              :rules="[{ required: true }]"
            />
            <van-field
              :value="fill_info.num + '人'"
              name="入住人数"
              label="入住人数"
              disabled
            />
            <van-field
              v-model="fill_info.beizhu"
              name="备注"
              label="备注"
              placeholder="请输入备注"
            />
            <van-button round block type="info" native-type="submit"
              >确认</van-button
            >
          </van-form>
        </div>
      </van-dialog>
      <Support ></Support>
    </div>
    <img v-else class="position-img" src="../../assets/null.svg" alt="" />
    <!-- <Support v-if="!isNull" :style=" !isNull ? 'position:absolute;bottom: .6rem;left: 50%; transform: translate(-50%, 0);' : ''"></Support> -->
    <Support v-if="!isNull"></Support>
    <sideBar></sideBar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import sideBar from "@/components/sidebar";
import Support from "@/components/support";
import { hexToRgba } from "@/js/until.js";
import Loading from "@/components/loading/loading";
export default {
  computed: {
    ...mapState(["themecolors"]),
    hexToRgba() {
      return hexToRgba(this.themecolors, 0.2);
    },
  },
  components: {
    BaiduMap,
    sideBar,
    Support,
    Loading,
  },
  data() {
    return {
      site_id: "",
      calendar: {
        show: false,
        date: ["2021-09-24", "2021-09-25"],
        number_nights: 1, // 几晚
        number_days: 3, // 可选天数
        minDate: new Date(2021, 0, 1), // 日期范围
        maxDate: new Date(2021, 11, 31), // 日期范围
        defaultdate: [],
      },
      applydia: false,
      msg: "",
      fillIndia: false,
      fill_info: {
        room_id: "",
        room_title: "",
        user_name: "",
        idcard: "",
        mobile: "",
        num: null,
        tow_user_name:"",
        beizhu:""
      },
      mapdia: false,
      hotel_info: {},
      isHttpData: true,
      isNull: false,
    };
  },
  async created() {
    sessionStorage.setItem("page_id", this.$route.query.page_id);
    sessionStorage.setItem("module_id", this.$route.query.module_id);
    this.site_id = this.$route.query.site_id;
    const res = await this.$store.dispatch("get_hotel_voucher_number", {
      site_id: this.site_id,
      module_id: this.$route.query.module_id,
    });
    if (res.data.code == 200) {
      document.title = res.data.data.module_title
        ? res.data.data.module_title
        : "轻微站";
      if (res.data.data.module_content.hotel_reservation_number) {
        this.$router.push({
          path: "/successful",
          query: {
            site_id: this.site_id,
            module_id: this.$route.query.module_id,
          },
        });
      }
    }
    if (res.data.code == 201) {
      this.$toast(res.data.setMessage);
    }
    this.get_hotel_information();
  },
  methods: {
    gohome() {
      this.$router.replace(
        `/event/${window.sessionStorage.getItem("siteUrl")}`
      );
    },
    // 添加入住酒店信息
    async add_hotel_user_info() {
      let params = {
        site_id: this.site_id,
        room_id: this.fill_info.room_id,
        starting_time: Date.parse(new Date(this.calendar.date[0])) / 1000,
        end_time: Date.parse(new Date(this.calendar.date[1])) / 1000,
        user_name: this.fill_info.user_name,
        tow_user_name: this.fill_info.tow_user_name,
        mobile: this.fill_info.mobile,
        living_population: this.fill_info.num,
        beizhu:this.fill_info.beizhu
      };
      const res = await this.$store.dispatch("add_hotel_user_info", params);
      // console.log(res);
      if (res.data.code == 200) {
        this.$toast(res.data.message);
        this.$router.push({
          path: "/successful",
          query: {
            site_id: this.site_id,
            module_id: this.$route.query.module_id,
          },
        });
      } else {
        this.$toast(res.data.message);
      }
    },
    async checkReserve(val) {
      if (val.remaining == 0) {
        this.$toast("房间已租完，请重新选择！");
        return;
      }
      const res = await this.$store.dispatch("get_hotel_booking", {
        site_id: this.site_id,
      });
      // if(res.data.code == 200){
      this.fillIndia = true;
      // }else{
      //     this.applydia = true;
      //     this.msg = res.data.message;
      // }
    },
    checkRoom(i) {
      // console.log(i);
      this.fill_info.room_id = i.id;
      this.fill_info.room_title = i.room_title;
    },
    changeNum(i) {
      this.fill_info.num = i;
      this.fill_info.room_id = "";
    },
    // 前台酒店信息展示
    async get_hotel_information() {
      const res = await this.$store.dispatch("get_hotel_information", {
        site_id: this.site_id,
      });
      if (res.data.code == 200) {
        this.isHttpData = false;
        this.isNull = true;
        this.hotel_info = res.data.data;
        if (res.data.data) {
          if (res.data.data.living_population.length > 0) {
            this.fill_info.num = res.data.data.living_population[0];
            let isSeek = true;
            res.data.data.room_id.forEach((item) => {
              if (isSeek) {
                if (item.quantity == this.fill_info.num) {
                  this.fill_info.room_id = item.id;
                  this.fill_info.room_title = item.room_title;
                  isSeek = false;
                }
              }
            });
          }
        }

        this.calendar.date = [
          this.getLocalTime(this.hotel_info.starting_time * 1000, 1),
          this.getLocalTime(this.hotel_info.end_time * 1000, 1),
        ];
        this.calendar.number_nights = this.computation(
          this.hotel_info.starting_time * 1000,
          this.hotel_info.end_time * 1000
        ); // 几晚
        this.calendar.number_days = this.computation(
          this.hotel_info.starting_time * 1000,
          this.hotel_info.end_time * 1000
        ); // 可选天数                 // 可选天数
        this.calendar.minDate = new Date(this.hotel_info.starting_time * 1000); // 日期范围
        this.calendar.maxDate = new Date(this.hotel_info.end_time * 1000); // 日期范围
        this.calendar.defaultdate = [
          this.calendar.minDate,
          this.calendar.maxDate,
        ];
      } else {
        this.isHttpData = false;
        this.isNull = false;
      }
    },
    getMap() {
      // await sessionStorage.setItem('map_address', this.hotel_info.map_address)
      // this.$router.push('/map')
      // location=40.047669,116.313082&title=我的位置&
      //    window.location.href = `http://api.map.baidu.com/marker?content=${this.hotel_info.details_address}&output=html&src=webapp.baidu.openAPIdemo`
      window.location.href = this.hotel_info.map_address;
    },
    // 校验身份证
    asyncValidator(val) {
      return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(val);
    },
    // 校验手机号
    validator(val) {
      return /^[1][3,4,5,7,8,9][0-9]{9}$/.test(val);
    },
    beforeClose() {},
    formatter(day) {
      if (day.type === "start") {
        day.bottomInfo = "入住";
      } else if (day.type === "end") {
        day.bottomInfo = "离店";
      }
      return day;
    },
    computation(sDate1, sDate2) {
      return parseInt(
        Math.abs(new Date(sDate1) - new Date(sDate2)) / 1000 / 60 / 60 / 24
      );
    },
    onConfirm(date) {
      const [start, end] = date;
      this.calendar.show = false;
      this.calendar.date = [
        this.getLocalTime(start, 1),
        this.getLocalTime(end, 1),
      ];
      this.calendar.number_nights = this.computation(
        this.calendar.date[0],
        this.calendar.date[1]
      );
    },
    // 格式化时间
    getLocalTime(date, type) {
      var now = new Date(date);
      var year = now.getFullYear();
      var month = now.getMonth() + 1;
      var date = now.getDate();
      var hour = now.getHours();
      var minute = now.getMinutes();
      var second = now.getSeconds();
      if (type == 1)
        return (
          year +
          "-" +
          (month < 10 ? "0" + month : month) +
          "-" +
          (date < 10 ? "0" + date : date)
        );
      if (type == 2)
        return (
          (hour < 10 ? "0" + hour : hour) +
          ":" +
          (minute < 10 ? "0" + minute : minute) +
          ":" +
          (second < 10 ? "0" + second : second)
        );
      if (type == 3)
        return (
          (month < 10 ? "0" + month : month) +
          "月" +
          (date < 10 ? "0" + date : date) +
          "日"
        );
    },
  },
};
</script>

<style lang="less" scoped>
@Color:var(--themecolor);
@rgbathemecolor:var(--rgbathemecolor);
.position-img {
  position: fixed;
  width: 3rem;
  height: 3rem;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
}
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  align-items: center;
}
.between {
  justify-content: space-between;
}
.center {
  justify-content: center;
}
.hotel-box {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  .main {
    min-height: 94%;
  }
  .hotel_null {
    position: absolute;
    width: 100%;
    font-size: .14rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #999;
    justify-content: center;
    height: calc(100% - .36rem);
  }
  /deep/.my-swipe,
  .banner {
    width: 100%;
    height: 1.8rem;
    border-radius: 0 0 0.2rem 0.2rem;
    overflow: hidden;
    img {
      width: 100%;
    }
    .swipe-img {
      object-fit: cover;
    }
  }
  .site-content {
    position: relative;
    width: 90%;
    max-height: 1.25rem;
    margin: 0 auto;
    margin-top: -0.35rem;
    box-shadow: 0 0 .1rem rgba(143, 143, 143, 0.2);
    border-radius: 0.1rem;
    background: #fff;
    padding: 0.15rem;
    h2 {
      font-size: 0.17rem;
      margin-bottom: 0.08rem;
    }
    p,
    span {
      font-size: 0.12rem;
    }
    img {
      width: 0.11rem;
      margin-right: 0.05rem;
    }
    .address {
      p {
        color: #666666;
        text-align: center;
      }
      span {
        color: #576e8e;
      }
      div {
        width: 85%;
        .vehicle {
          text-align: left;
          display: flex;
          align-items: flex-start;
          img {
            margin-top: 0.04rem;
          }
        }
        span {
          display: inline-block;
          width: 92%;
          color: #666666;
        }
      }
    }
  }
  .prompt {
    height: 0.35rem;
    background: linear-gradient(180deg, #fbf8f3 0%, #fde1dd 100%);
    border-radius: 0.05rem 0.05rem 0 0;
    margin-top: 0.15rem;
    color: #e36e5a;
    font-size: 0.12rem;
    font-weight: bold;
    padding: 0 0.25rem;
    justify-content: flex-start !important;
    span {
      font-weight: normal;
    }
  }
  .date-tabs {
    width: 90%;
    height: 0.35rem;
    background: #f7f8fc;
    border-radius: 0.05rem;
    margin: 0.12rem auto;
    padding: 0 0.1rem;
    position: sticky;
    p {
      font-size: 0.14rem;
    }
    span {
      font-size: 0.12rem;
      margin-left: 0.02rem;
      color: #666666;
    }
    div {
      width: 0.4rem;
      max-width: 0.6rem;
      height: 0.22rem;
      border: .01rem solid #666666;
      border-radius: 0.9rem;
      color: #666666;
      font-size: 0.12rem;
      text-align: center;
    }
  }
  .check-in {
    padding: 0 0.2rem;
    font-size: 0.12rem;
    flex-wrap: wrap;
    justify-content: flex-start;
    span {
      color: #e5514f;
      margin-right: 0.05rem;
    }
    p {
      color: #333333;
      font-size: 0.14rem;
    }
    .num {
      width: 0.4rem;
      height: 0.3rem;
      background: #f7f8fc;
      border-radius: 0.1rem;
      color: #666666;
      margin: .03rem 0.05rem;
    }
  }
  .hotel {
    width: 90%;
    height: 100%;
    margin: 0.1rem auto;
    flex-wrap: wrap;
    display: flex;
    display: -webkit-flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    .van-radio-group {
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .hotel-item {
      width: 1.65rem;
      // height: 1.1rem;
      border-radius: 0.1rem;
      padding: 0.15rem 0.09rem;
      border: .01rem solid #cfd0d6;
      margin-bottom: 0.1rem;
      .room_title{
        margin-bottom: 0.02rem;
      }
      .room_price{
        flex: 1;
      }
      .room_remaining{
        flex: none;
        margin: 0 0.05rem;
        font-size: 0.12rem;
        color: #999999;
      }
      .top {
        align-items: flex-start;
        min-height: 50%;
        p {
          width: 85%;
        }
      }
      // .bottom {
      //   align-items: flex-end;
      // }
      .price {
        color: #e5514f;
        font-size: 0.14rem;
      }
      img {
        width: 0.22rem;
      }
      p {
        font-size: 0.14rem;
      }
      span {
        font-size: 0.12rem;
        color: #999999;
      }
      button {
        width: 0.40rem;
        height: 0.25rem;
        background: #00ccb0;
        border-radius: 0.14rem;
        font-size: 0.12rem;
        color: #fff;
        border: none;
      }
    }
  }
  .vant-dialog,
  .vant-fill-dialog {
    padding: 0.2rem;
    text-align: center;
    .header {
      justify-content: flex-end;
    }
    p {
      margin: 0.2rem 0 0;
      font-size: 0.14rem;
      color: #333333;
    }
    button {
      width: 1rem;
      height: 0.4rem;
      background: #00ccb0;
      border-radius: .55rem;
      color: #fff;
      border: none;
      margin: 0.2rem 0 0;
    }
  }
  .vant-fill-dialog {
    padding: 0;
    text-align: left;
    .header {
      padding: 0 0.2rem;
      justify-content: space-between;
      height: 0.5rem;
      background: linear-gradient(90deg, #8cede5 0%, #01ccb0 100%);
      color: #fff;
    }
    .user-info {
      justify-content: center;
      padding: 0.1rem 0.2rem 0.2rem;
      line-height: 0.3rem;
      h2 {
        padding: 0 0.14rem;
        font-size: 0.15rem;
      }
      p {
        padding: 0 0.14rem;
        font-size: 0.14rem;
        margin: 0;
      }
      button {
        margin: 0.2rem auto 0;
      }
    }
    /deep/.van-cell__title {
      color: #66656a;
    }
    /deep/.van-field__control:disabled {
      color: #262739;
      -webkit-text-fill-color: #262739;
    }
  }
  .vant-map-dialog {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 2222;
    font-size: 0.12rem;
    .header {
      position: absolute;
      z-index: 3333;
      display: flex;
      top: 0.2rem;
      right: 0.2rem;
      font-size: 0.24rem;
        color: @Color;
    }
  }
}
</style>
